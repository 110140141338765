.login-card {
    height: 60vh;
    align-self: center;
    /* width: calc(200px + 30%); */
}
.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}
.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 20px;
    margin: 40px auto;
}

.errorMessage {
    color: red;
    margin: 0;
    padding: 0;
    line-height: 0;
}