.errorTitle {
    font-weight: bolder;
    font-size: 8em;
}

.errorDescription {
    font-weight: bold;
}

.content {
    width: fit-content;
    text-align:center;
    margin: auto;
    margin-top: 30vh;
}