.head {
    margin: 10px;
    background-color: rgb(38, 38, 38);
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 30px;
}

.title {
    margin-left: 30px;
    height: fit-content;
    display: flex;
}

.router {
    margin: 10px;
    height: fit-content;
    display: flex;
}
.navButton {
    margin: 10px;
    height: fit-content;
}

.App {
  color: azure;
}

.mainContent {
    margin: 10px 25px;
}